<template>
	<div class="user">
		关于我们
		<img src="@/static/img/kou.jpg" alt="" />
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		mounted() {
			document.title = '广东乐养堂大健康';
			console.log('进来了')
		},
		methods:{
			
		}
	}
</script>

<style>
	.user{
		width: 100%;
		height: auto;
		margin-top: 100px;
	}
</style>